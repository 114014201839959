<template src="./ChangeHome.html"></template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "change-home",
  methods: {
    changeHome() {
      if (this.initHome) {
        this.$store.dispatch("fetchInitialHome", false);
      } else {
        this.$store.dispatch("fetchInitialHome", true);
      }
    },
    ...mapActions({
      fetchInitialHome: "fetchInitialHome",
    }),
  },
  computed: {
    ...mapGetters({
      initialHome: "getInitialHome",
    }),
  },
};
</script>

<style lang="scss" scoped src="./ChangeHome.scss"></style>