<template src="./ModalDataUpdate.html"></template>

<script>
import idiService from "@/services/Idi";
import { required } from "vuelidate/lib/validators";

export default {
  name: "modal-data-update",
  props: {
    openModalDataUpdate: { type: Boolean, default: true },
  },
  data() {
    return {
      institutions: [],
      selectedInstitution: [],
      modalConfirmationUpdate: false,
    };
  },
  validations: {
    selectedInstitution: { required },
  },
  created() {
    this.getListInstitutions();
  },
  methods: {
    async getListInstitutions() {
      try {
        this.institutions = await idiService.listInstitutions();
      } catch (e) {
        this.$buefy.toast.open({
          duration: 5000,
          message: e.response?.data?.message ?? "Se presentó un error",
          position: "is-bottom",
          type: "is-danger",
        });
        console.error("Error: ChooseUniversity/getListInstitutions()", e);
      }
    },
    async sendRequest() {
      this.modalConfirmationUpdate = true;
      this.$emit("sendDataToUpdate", this.selectedInstitution);
    },
  },
};
</script>

<style lang="scss" scoped src="./ModalDataUpdate.scss">
</style>